/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Note: The line below makes the Authenticator HOC functional.
 * Without it, the app will not compile.
 */
//eslint-disable-next-line
(window as any).global = window;
